import { css } from "@emotion/react";

export const GlobalStyle = css`
  * {
    box-sizing: border-box;
  }
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(14, 19, 24, 0.4);
    border-radius: 4px;
  }
  body {
    overflow-x: hidden;
    overflow-y: overlay;
    margin: 0;
  }
  html {
    font-family: "Nunito Sans", "prompt", sans-serif;
    scrollbar-width: thin;
    scroll-behavior: smooth;
  }
`;
