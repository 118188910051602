import React, { memo } from "react";
import { SvgIcon } from "@material-ui/core";

const LogoFooter = memo(({ onClick }) => {
  return (
    <SvgIcon
      onClick={onClick}
      style={{
        width: "auto",
        height: "50px",
        cursor: "pointer",
      }}
      viewBox="0 0 845 283"
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M112.7 282.9H91.5c-9.3-.6-18.5-.9-27.8-1.9-5.3-.5-10.6-1.8-15.8-2.9-1.2-.3-2.4-.8-3.4-1.5-1.5-.9-1.6-2.3 0-2.9 2.3-1.1 4.7-1.9 7.2-2.5 7.6-1.6 15.4-2.6 23.2-2.9 9.7-.5 19.5-.8 29.3-.9 12-.1 24.1.5 36 1.9 5.6.7 11.2 1.3 16.6 3.1 1.1.4 2.1.8 3 1.5 1.2.8 1.2 1.9 0 2.7-1.1.8-2.3 1.4-3.6 1.7-5.3 1.1-10.5 2.3-15.8 2.9-9.1.7-18.4 1.1-27.7 1.7"
          fill="#808184"
        />
        <path
          d="M0 173.2c.3-1.6.5-3.3 1-4.9 1.8-7.4 4.9-14.2 10.3-19.7 4-4.1 9.1-6.9 14.7-8.2.2-.1.4-.1.7-.1-.1 4-.3 7.8-.4 11.7-.2 5.6-.3 11.1-.5 16.6 0 .7-.3 1.3-.7 1.8-3.4 4.4-6.2 8.9-6.9 14.6-.6 4.9-.3 9.8.8 14.6.7 3.3 1.7 6.6 2.6 9.9.7 2.3 0 4.3-1.9 5.3s-3.9.4-5.3-1.7c-4.9-7.4-9.4-15.1-12-23.7-1-3.3-1.4-6.7-2.2-10-.1-.2-.1-.5-.2-.7v-5.5Z"
          fill="#F1AF1D"
        />
        <path
          d="M104 118.1c3.7-.2 9.4-.4 15.2-.7 8.3-.4 16.5-1.7 24.5-3.7 6.4-1.6 12.5-3.9 17.9-7.9 5.2-4 8.3-9 8.2-15.7 0-4.9-1-9.7-2.8-14.2-3.7-9.4-10.1-16.6-18.2-22.3-14.6-10.3-30.9-14.6-48.7-14.4-7.5.1-15 1.3-22.2 3.4-12 3.6-22.7 9.3-31.3 18.4-6.4 6.8-10.7 14.7-12.1 24-.6 3.9-.7 7.8.7 11.7 2 5.2 5.8 8.6 10.5 11.3 6.2 3.6 13 5.5 20 6.9 12 2.5 24.1 3 38.3 3.2m-2.1 13.4c-9.1-.3-18.2-.3-27.3-.8-8.6-.5-17.2-1.3-25.8-2.1-4.7-.5-9-2.3-13.3-4.1-7.3-3-14-6.8-19.4-12.5-6.2-6.3-9.701-14.8-9.701-23.7C6.3 67.4 13.4 49.1 27 33.3 41.7 16.4 60.1 6 81.9 2.1c28.7-5.3 55.4-.1 79.4 17 15.9 11.3 27 26.3 32.9 45 2.5 8 3.7 16.4 3.5 24.8-.3 8.8-3.7 16.4-9.8 22.8-6.6 6.9-14.9 11.1-23.7 14.4-5.3 2-10.8 2.8-16.4 3.4-10.6 1-21.2 1.6-31.9 1.7-4.7.1-9.3 0-13.9 0l-.1.3M43.3 134.3h117.4c.1 1 .1 1.9.2 2.9.3 8.2.6 16.5.9 24.7.1 2.5.4 5.2.3 7.7-.1 2.1-.5 4.1-1.1 6.1-4.2 15.9-10.7 30.8-20.4 44.1-5.7 7.9-12.4 14.9-20.8 19.9-6 3.5-12.5 6-19.5 5.4-6.7-.5-12.8-3.1-18.4-7-8.2-5.5-14.7-12.8-20.2-20.9-9.2-13.7-15.8-29-19.4-45.1-.6-2.5-.2-5.2-.1-7.8.2-6.9.5-13.8.8-20.7.1-3 .2-6 .3-9.3"
          fill="#F1AE1C"
        />
        <path
          d="M177.4 140.3c8.4 1.9 14.9 6.4 19.4 13.5 4.5 7 7.3 14.7 7.1 23.2-.2 6.6-2 12.8-4.6 18.7-2.8 6.2-6.1 12.1-9.9 17.6-1.2 1.9-3.6 2.5-5.5 1.3-1.6-1-2.3-3-1.6-4.8 1.6-4.7 2.7-9.6 3.5-14.5 1.4-9.4-.7-17.6-6.8-24.8-.4-.6-.7-1.2-.7-1.9-.3-9.2-.6-18.3-.9-27.5v-.8"
          fill="#F1AF1D"
        />
        <path
          d="M165 171c-.5-12.2-.9-24.3-1.3-36.7h8.8c1.3.1 1.6.4 1.7 1.8l.8 24.1c.1 2.9.2 5.8.3 8.6.1 1.7-.3 2.1-2 2.1l-8.3.1M40.3 134.3c-.5 12.4-.9 24.5-1.3 36.6-3.1 0-6.1.1-9.1 0-.9 0-1.2-.7-1.2-1.5.2-5.2.3-10.5.5-15.7.1-4.8.3-9.5.5-14.3.1-1.1.1-2.3.1-3.4-.1-.8.4-1.5 1.2-1.6h.3c3-.1 5.9-.1 9-.1"
          fill="#F1AF1E"
        />
        <path
          d="M102.6 94.5c2.3 0 4.1-1.8 5.5-4 .2-.5 0-1.1-.3-1.5-.5-.2-1.1-.2-1.6.1-.5.5-1 1-1.4 1.6-1.3 1.5-3.5 1.6-5 .4-.2-.1-.3-.3-.4-.4-.4-.6-.9-1.1-1.4-1.6-.5-.3-1.1-.3-1.6 0-.3.4-.4 1-.3 1.5 1.3 2.1 3.1 3.9 6.5 3.9m-24.4-11c0-2.7-.6-5.3-2-7.6-3.5-6.1-10.6-7-15.1-1.8s-4.3 14 .3 19c3.7 4 9.3 4.2 13 .3 2.8-2.8 3.8-6.2 3.8-9.9m47.6 0c-.1 3.4 1 6.6 3.2 9.2 3.7 4.4 9.5 4.7 13.5.5 4.7-5 4.9-14.2.3-19.3-3.9-4.4-9.9-4.4-13.7.2-2.3 2.6-3.4 5.9-3.3 9.4M104 118.4c-14.3-.2-26.3-.7-38.3-3.1-7-1.4-13.8-3.3-20-6.9-4.7-2.7-8.5-6.1-10.5-11.4-1.4-3.8-1.3-7.7-.7-11.7 1.4-9.3 5.7-17.2 12.1-24 8.7-9.2 19.4-14.9 31.3-18.5 7.2-2.2 14.7-3.3 22.2-3.4 17.7-.3 34.1 4.1 48.7 14.4 8.1 5.7 14.5 13 18.2 22.4 1.8 4.5 2.8 9.3 2.8 14.2.1 6.7-3 11.8-8.2 15.7-5.3 4.1-11.5 6.3-17.9 7.9-8 2-16.2 3.3-24.5 3.7-5.8.4-11.5.6-15.2.7"
          fill="#FFF"
        />
        <path
          d="M142.7 73.9c-3.9-4.4-9.9-4.4-13.7.2-4.2 5.5-4.2 13.1 0 18.6 3.7 4.4 9.5 4.7 13.5.5 4.6-4.9 4.8-14.1.2-19.3M61.2 74.1c-4.4 5.1-4.3 14 .3 19 3.7 4 9.3 4.2 13 .3 2.6-2.8 3.7-6.1 3.7-9.9 0-2.7-.6-5.3-2-7.6-3.4-6.2-10.5-7-15-1.8M107.7 89.1c-.5-.2-1.1-.2-1.6.1-.5.5-1 1-1.4 1.6-1.3 1.5-3.5 1.6-5 .4-.2-.1-.3-.3-.4-.4-.4-.6-.9-1.1-1.4-1.6-.5-.3-1.1-.3-1.6 0-.3.4-.4 1-.3 1.5 1.4 2.2 3.2 3.9 6.6 3.9 2.3 0 4.1-1.8 5.5-4 .1-.6 0-1.2-.4-1.5"
          fill="#101111"
        />
        <g fill="#FFFFFF" fillRule="nonzero">
          <path d="M281.3 279.1c-3.5-1.8-6.4-4.6-8.3-8-3.9-7.6-3.9-16.6 0-24.2 1.9-3.4 4.8-6.3 8.3-8 3.8-1.9 8-2.9 12.3-2.8 6.1 0 11.2 1.5 15.3 4.4v7.6c-3.7-3.1-8.5-4.6-14.6-4.6-3-.1-5.9.6-8.5 2-2.4 1.2-4.3 3.1-5.7 5.4-2.7 5.2-2.7 11.4 0 16.6 1.3 2.3 3.3 4.2 5.7 5.4 2.6 1.3 5.5 2 8.5 2 2.8 0 5.6-.4 8.3-1.1 2.5-.8 4.8-2.1 6.7-3.7v7.6c-3.9 3-9.1 4.6-15.8 4.6-4.2-.3-8.4-1.3-12.2-3.2ZM359.9 236.8h18.5c4.9 0 8.6 1.2 11.3 3.6 2.7 2.4 4 5.9 4 10.4.1 3-.8 5.9-2.4 8.3-1.7 2.3-4.1 4-6.8 4.9l8.9 17h-8.2l-8.5-16.2H368V281h-8l-.1-44.2Zm16.9 21.4c2.3.1 4.5-.6 6.3-1.9 1.6-1.2 2.4-3.1 2.4-5.5 0-5-2.9-7.4-8.7-7.4H368v14.7l8.8.1ZM444.9 236.8h29.8v6.7h-21.9v12.1h16.3v6.7h-16.3v12.1h21.9v6.7h-29.8zM547.8 236.8h7.7l17.6 44.1H565l-4.1-10.2h-18.7l-4 10.2h-8.1l17.7-44.1Zm11 27.4-7.2-18.8-7.2 18.8h14.4ZM640.6 243.4h-13.3v-6.7h34.6v6.7h-13.3v37.5h-8zM720.3 279c-3.5-1.9-6.4-4.7-8.4-8.1-2.1-3.6-3.2-7.8-3.1-12-.1-4.2 1-8.4 3.1-12 2-3.4 4.9-6.2 8.4-8.1 3.7-2 7.8-3 12-2.9 4.1-.1 8.2.9 11.9 2.9 3.5 1.9 6.4 4.7 8.4 8.1 2.1 3.7 3.2 7.8 3.1 12 .1 4.2-1 8.3-3.1 12-2 3.4-4.9 6.2-8.4 8.1-7.6 3.9-16.5 3.9-23.9 0Zm19.6-6.3c2.3-1.3 4.2-3.2 5.5-5.5 2.7-5.2 2.7-11.3 0-16.5-1.3-2.3-3.2-4.2-5.5-5.5-2.4-1.3-5-2-7.7-2s-5.4.6-7.8 2c-2.3 1.3-4.2 3.2-5.5 5.5-2.7 5.2-2.7 11.3 0 16.5 1.3 2.3 3.2 4.2 5.5 5.5 2.4 1.3 5 2 7.7 2 2.7-.1 5.4-.7 7.8-2ZM801.6 236.8h18.5c4.9 0 8.6 1.2 11.3 3.6 2.7 2.4 4 5.9 4 10.4.1 3-.8 5.9-2.4 8.3-1.7 2.3-4.1 4-6.8 4.9l8.9 17h-8.2l-8.5-16.2h-8.7V281h-8l-.1-44.2Zm16.9 21.4c2.3.1 4.5-.6 6.3-1.9 1.6-1.2 2.4-3.1 2.4-5.5 0-5-2.9-7.4-8.7-7.4h-8.9v14.7l8.9.1Z" />
        </g>
        <path
          d="M358.5 99.7c.3-.2.5-.2.6-.2 12.5-6 23.5-14.8 32.2-25.6 4.4-5.3 7.9-11.2 9.2-18.1 1.5-7.2-.2-13.4-6-18.3-3.2-2.7-6.7-4.5-11-3.3-2.5.6-4.8 1.6-7 3-10.1 6.7-15.8 16.4-17.8 28.3-1.4 8.6-1.8 17.4-1.1 26.1.2 2.6.6 5.2.9 8.1m-20.7 42.1c-12.4 2.7-25.1 4.1-37.7 4.4v30.3c0 6.2-2.4 11.2-7.6 14.6-10.6 7-24.9-.3-25.5-13V19.2c0-8.1 4.7-14.6 12.1-16.7 8.8-2.5 17.9 2.6 20.4 11.4.3 1.2.5 2.5.6 3.7.1 1.1.1 2.3.1 3.3v91.7c8.9-.2 17.9-1.2 26.6-2.8-.4-2.4-.8-4.9-1.1-7.2-1.2-9.3-1.5-18.6-1-27.9.6-9.2 1.5-18.4 4.5-27.1 6.2-17.7 17.1-31.8 33.7-41.1C370.8 2 379.8-.1 388.8.4c8.8.5 17.2 3.7 24.1 9 13.1 9.7 20.2 22.8 21.1 39.2.6 11.6-2.5 22.3-7.9 32.4-5.9 10.9-13.9 20.2-23.1 28.5-9.4 8.5-20.1 15.6-31.7 21-.3.2-.7.3-1.4.6 2.2 2.5 4.1 5.1 6.3 7.5 9.1 9.5 20.4 15.1 33 18.3 5.5 1.4 11.3 2.2 16.9 3.2 9.1 1.5 15.3 10.1 13.8 19.2-1.1 6.9-6.5 12.4-13.4 13.7-2.9.6-5.8.1-8.7-.3-11.9-1.5-23.6-4.7-34.7-9.5-19.5-8.6-34.7-22.1-45-40.8 0-.2-.1-.4-.3-.6M677.8.4c.4.2.9.2 1.3.4 3.7 1.1 6.1 4.5 6 8.3v56.6c0 14.4-.1 28.9 0 43.3-.1 8.4 1.1 16.7 3.6 24.7 6.5 20 20.2 32.2 40.3 37.6 4.8 1.3 9.7 2 14.6 2.1 4.6.1 8.2 3.8 8.2 8.4.1 4.5-3.5 8.3-8 8.3h-.4c-8.2-.3-16.2-1.9-24-4.5-11.1-3.7-21.1-9.4-29.4-17.7-7.9-7.9-13.7-17.5-17-28.2-3.1-10-4.7-20.4-4.6-30.9V9.3c0-4.9 2.3-7.9 7-9 .1 0 .1-.1.2-.2h2.3l-.1.3Z"
          fill="#FFFFFF"
        />
        <path
          d="M803.6.4c.9.3 1.9.6 2.8 1.1 2.7 1.3 4.5 4.2 4.4 7.2 0 32 .1 64.1 0 96.1-.1 10.9-2.2 21.4-7.5 31-6.3 11.4-15.6 19.3-28.3 22.7-1.9.5-3.8.8-5.7.9-4.3.2-8-3-8.5-7.3-.4-4.9 2.6-8.7 7.2-9.4 7.8-1.1 13.8-5.1 18.4-11.3 4-5.5 6.1-11.8 7-18.5.5-4 .7-7.9.7-11.9.1-30.4 0-60.8 0-91.2 0-5 2.2-7.9 7-9.2.1 0 .1-.1.2-.2h2.3ZM711.2.4l1.5.5c3.3 1.1 5.6 4.1 5.8 7.6v95c0 7.8 1.1 15.3 4.5 22.4 4 8.3 10 13.9 19 16.3 1.1.2 2.2.3 3.3.6 4 .9 6.9 4.9 6.4 8.7-.5 4.7-3.8 7.9-8.3 7.8-4.7-.1-9-1.5-13.2-3.5-12.5-6-20.3-16-24.9-28.8-2.1-6.4-3.4-13-3.6-19.8-.2-3.1-.2-6.2-.2-9.2 0-29.4 0-58.9-.1-88.4 0-4.9 3-8.6 7-9.2.1 0 .1-.1.2-.2l2.6.2Z"
          fill="#F1AF1D"
        />
        <path
          d="M833.9.7c3.6-.6 6.6.6 8.7 3.6 1 1.5 1.5 3.2 1.4 5v102.3c-.1 9.4-1.6 18.7-4.4 27.6-6.6 20.7-19.9 35.2-39.7 43.9-9.5 4.2-19.6 6.6-29.9 7.2-4.5.2-7.9-2-9.1-6-1.3-4.5 1.3-9.2 5.8-10.5.7-.2 1.4-.3 2.2-.3 11.4-.4 22.5-3.8 32.2-9.9 11.9-7.5 19.4-18.5 23.2-31.9 2-7.2 3.1-14.6 3-22.1V9.7c0-3.8 1.4-6.7 4.9-8.4.6-.2 1.1-.5 1.7-.6"
          fill="#FFFFFF"
        />
        <path
          d="M474.2 97.6c-.1-7.9 6.2-14.4 14.1-14.6h.1c7.8 0 14.1 6.5 14.2 14.6.1 8-6.3 14.5-14.3 14.6-7.8 0-14.1-6.5-14.1-14.6M474.2 139c-.1-7.9 6.2-14.4 14.1-14.6h.1c7.8 0 14.2 6.6 14.2 14.6 0 7.9-6.3 14.4-14.2 14.6-7.8-.1-14.2-6.6-14.2-14.6M605.4 56.3c0-8.1 6.2-14.6 14.2-14.6 7.8-.1 14.2 6.6 14.2 14.7.1 7.9-6.2 14.4-14.2 14.5h-.1c-7.8 0-14.1-6.5-14.1-14.6M605.4 97.6c0-8.1 6.3-14.6 14.1-14.5 8 .1 14.3 6.6 14.3 14.6s-6.4 14.6-14.2 14.6c-7.9-.1-14.2-6.6-14.2-14.7M474.2 56.3c-.1-7.9 6.2-14.4 14.1-14.5 7.7-.1 14.3 6.6 14.3 14.6.1 8-6.3 14.5-14.3 14.6-7.8-.1-14.1-6.6-14.1-14.7M488.5.4C496.6.4 503 7 503 15.1s-6.6 14.5-14.7 14.5c-7.9 0-14.1-6.6-14.1-15 0-7.8 6.4-14.2 14.3-14.2M605.4 138.9c0-8.1 6.2-14.6 14.1-14.6 8.1 0 14.6 6.5 14.6 14.5 0 8.1-6.5 14.6-14.5 14.6-7.8.1-14.2-6.3-14.2-14.5M575.9 194.8c-7.9-.1-14.2-6.6-14.2-14.5 0-8.1 6.4-14.7 14.2-14.7 7.8 0 14.2 6.6 14.2 14.7 0 8-6.3 14.4-14.2 14.5M532.2 194.8c-8 .3-14.8-6-15.1-14.1-.3-8 6-14.8 14.1-15.1 8-.3 14.8 6 15.1 14.1v.4c0 8.2-6.2 14.7-14.1 14.7M605.4 14.9c0-8.2 6.2-14.6 14.3-14.5 7.9 0 14.1 6.6 14.1 14.6.2 7.8-5.9 14.4-13.7 14.6-7.8.2-14.4-5.9-14.6-13.7-.1-.3-.1-.6-.1-1"
          fill="#F1AF1D"
        />
      </g>
    </SvgIcon>
  );
});

const Logo = memo((props) => {
  return (
    <SvgIcon
      style={{
        width: "100%",
        height: "100%",
        cursor: "pointer",
        ...props.style,
      }}
      viewBox="0 0 845 283"
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M112.7 282.9H91.5c-9.3-.6-18.5-.9-27.8-1.9-5.3-.5-10.6-1.8-15.8-2.9-1.2-.3-2.4-.8-3.4-1.5-1.5-.9-1.6-2.3 0-2.9 2.3-1.1 4.7-1.9 7.2-2.5 7.6-1.6 15.4-2.6 23.2-2.9 9.7-.5 19.5-.8 29.3-.9 12-.1 24.1.5 36 1.9 5.6.7 11.2 1.3 16.6 3.1 1.1.4 2.1.8 3 1.5 1.2.8 1.2 1.9 0 2.7-1.1.8-2.3 1.4-3.6 1.7-5.3 1.1-10.5 2.3-15.8 2.9-9.1.7-18.4 1.1-27.7 1.7"
          fill="#4C4C4D"
        />
        <path
          d="M0 173.2c.3-1.6.5-3.3 1-4.9 1.8-7.4 4.9-14.2 10.3-19.7 4-4.1 9.1-6.9 14.7-8.2.2-.1.4-.1.7-.1-.1 4-.3 7.8-.4 11.7-.2 5.6-.3 11.1-.5 16.6 0 .7-.3 1.3-.7 1.8-3.4 4.4-6.2 8.9-6.9 14.6-.6 4.9-.3 9.8.8 14.6.7 3.3 1.7 6.6 2.6 9.9.7 2.3 0 4.3-1.9 5.3s-3.9.4-5.3-1.7c-4.9-7.4-9.4-15.1-12-23.7-1-3.3-1.4-6.7-2.2-10-.1-.2-.1-.5-.2-.7v-5.5Z"
          fill="#F1AF1D"
        />
        <path
          d="M104 118.1c3.7-.2 9.4-.4 15.2-.7 8.3-.4 16.5-1.7 24.5-3.7 6.4-1.6 12.5-3.9 17.9-7.9 5.2-4 8.3-9 8.2-15.7 0-4.9-1-9.7-2.8-14.2-3.7-9.4-10.1-16.6-18.2-22.3-14.6-10.3-30.9-14.6-48.7-14.4-7.5.1-15 1.3-22.2 3.4-12 3.6-22.7 9.3-31.3 18.4-6.4 6.8-10.7 14.7-12.1 24-.6 3.9-.7 7.8.7 11.7 2 5.2 5.8 8.6 10.5 11.3 6.2 3.6 13 5.5 20 6.9 12 2.5 24.1 3 38.3 3.2m-2.1 13.4c-9.1-.3-18.2-.3-27.3-.8-8.6-.5-17.2-1.3-25.8-2.1-4.7-.5-9-2.3-13.3-4.1-7.3-3-14-6.8-19.4-12.5-6.2-6.3-9.701-14.8-9.701-23.7C6.3 67.4 13.4 49.1 27 33.3 41.7 16.4 60.1 6 81.9 2.1c28.7-5.3 55.4-.1 79.4 17 15.9 11.3 27 26.3 32.9 45 2.5 8 3.7 16.4 3.5 24.8-.3 8.8-3.7 16.4-9.8 22.8-6.6 6.9-14.9 11.1-23.7 14.4-5.3 2-10.8 2.8-16.4 3.4-10.6 1-21.2 1.6-31.9 1.7-4.7.1-9.3 0-13.9 0l-.1.3M43.3 134.3h117.4c.1 1 .1 1.9.2 2.9.3 8.2.6 16.5.9 24.7.1 2.5.4 5.2.3 7.7-.1 2.1-.5 4.1-1.1 6.1-4.2 15.9-10.7 30.8-20.4 44.1-5.7 7.9-12.4 14.9-20.8 19.9-6 3.5-12.5 6-19.5 5.4-6.7-.5-12.8-3.1-18.4-7-8.2-5.5-14.7-12.8-20.2-20.9-9.2-13.7-15.8-29-19.4-45.1-.6-2.5-.2-5.2-.1-7.8.2-6.9.5-13.8.8-20.7.1-3 .2-6 .3-9.3"
          fill="#F1AE1C"
        />
        <path
          d="M177.4 140.3c8.4 1.9 14.9 6.4 19.4 13.5 4.5 7 7.3 14.7 7.1 23.2-.2 6.6-2 12.8-4.6 18.7-2.8 6.2-6.1 12.1-9.9 17.6-1.2 1.9-3.6 2.5-5.5 1.3-1.6-1-2.3-3-1.6-4.8 1.6-4.7 2.7-9.6 3.5-14.5 1.4-9.4-.7-17.6-6.8-24.8-.4-.6-.7-1.2-.7-1.9-.3-9.2-.6-18.3-.9-27.5v-.8"
          fill="#F1AF1D"
        />
        <path
          d="M165 171c-.5-12.2-.9-24.3-1.3-36.7h8.8c1.3.1 1.6.4 1.7 1.8l.8 24.1c.1 2.9.2 5.8.3 8.6.1 1.7-.3 2.1-2 2.1l-8.3.1M40.3 134.3c-.5 12.4-.9 24.5-1.3 36.6-3.1 0-6.1.1-9.1 0-.9 0-1.2-.7-1.2-1.5.2-5.2.3-10.5.5-15.7.1-4.8.3-9.5.5-14.3.1-1.1.1-2.3.1-3.4-.1-.8.4-1.5 1.2-1.6h.3c3-.1 5.9-.1 9-.1"
          fill="#F1AF1E"
        />
        <path
          d="M102.6 94.5c2.3 0 4.1-1.8 5.5-4 .2-.5 0-1.1-.3-1.5-.5-.2-1.1-.2-1.6.1-.5.5-1 1-1.4 1.6-1.3 1.5-3.5 1.6-5 .4-.2-.1-.3-.3-.4-.4-.4-.6-.9-1.1-1.4-1.6-.5-.3-1.1-.3-1.6 0-.3.4-.4 1-.3 1.5 1.3 2.1 3.1 3.9 6.5 3.9m-24.4-11c0-2.7-.6-5.3-2-7.6-3.5-6.1-10.6-7-15.1-1.8s-4.3 14 .3 19c3.7 4 9.3 4.2 13 .3 2.8-2.8 3.8-6.2 3.8-9.9m47.6 0c-.1 3.4 1 6.6 3.2 9.2 3.7 4.4 9.5 4.7 13.5.5 4.7-5 4.9-14.2.3-19.3-3.9-4.4-9.9-4.4-13.7.2-2.3 2.6-3.4 5.9-3.3 9.4M104 118.4c-14.3-.2-26.3-.7-38.3-3.1-7-1.4-13.8-3.3-20-6.9-4.7-2.7-8.5-6.1-10.5-11.4-1.4-3.8-1.3-7.7-.7-11.7 1.4-9.3 5.7-17.2 12.1-24 8.7-9.2 19.4-14.9 31.3-18.5 7.2-2.2 14.7-3.3 22.2-3.4 17.7-.3 34.1 4.1 48.7 14.4 8.1 5.7 14.5 13 18.2 22.4 1.8 4.5 2.8 9.3 2.8 14.2.1 6.7-3 11.8-8.2 15.7-5.3 4.1-11.5 6.3-17.9 7.9-8 2-16.2 3.3-24.5 3.7-5.8.4-11.5.6-15.2.7"
          fill="#FFF"
        />
        <path
          d="M142.7 73.9c-3.9-4.4-9.9-4.4-13.7.2-4.2 5.5-4.2 13.1 0 18.6 3.7 4.4 9.5 4.7 13.5.5 4.6-4.9 4.8-14.1.2-19.3M61.2 74.1c-4.4 5.1-4.3 14 .3 19 3.7 4 9.3 4.2 13 .3 2.6-2.8 3.7-6.1 3.7-9.9 0-2.7-.6-5.3-2-7.6-3.4-6.2-10.5-7-15-1.8M107.7 89.1c-.5-.2-1.1-.2-1.6.1-.5.5-1 1-1.4 1.6-1.3 1.5-3.5 1.6-5 .4-.2-.1-.3-.3-.4-.4-.4-.6-.9-1.1-1.4-1.6-.5-.3-1.1-.3-1.6 0-.3.4-.4 1-.3 1.5 1.4 2.2 3.2 3.9 6.6 3.9 2.3 0 4.1-1.8 5.5-4 .1-.6 0-1.2-.4-1.5"
          fill="#101111"
        />
        <g fill="#3E3E3F" fillRule="nonzero">
          <path d="M281.3 279.1c-3.5-1.8-6.4-4.6-8.3-8-3.9-7.6-3.9-16.6 0-24.2 1.9-3.4 4.8-6.3 8.3-8 3.8-1.9 8-2.9 12.3-2.8 6.1 0 11.2 1.5 15.3 4.4v7.6c-3.7-3.1-8.5-4.6-14.6-4.6-3-.1-5.9.6-8.5 2-2.4 1.2-4.3 3.1-5.7 5.4-2.7 5.2-2.7 11.4 0 16.6 1.3 2.3 3.3 4.2 5.7 5.4 2.6 1.3 5.5 2 8.5 2 2.8 0 5.6-.4 8.3-1.1 2.5-.8 4.8-2.1 6.7-3.7v7.6c-3.9 3-9.1 4.6-15.8 4.6-4.2-.3-8.4-1.3-12.2-3.2ZM359.9 236.8h18.5c4.9 0 8.6 1.2 11.3 3.6 2.7 2.4 4 5.9 4 10.4.1 3-.8 5.9-2.4 8.3-1.7 2.3-4.1 4-6.8 4.9l8.9 17h-8.2l-8.5-16.2H368V281h-8l-.1-44.2Zm16.9 21.4c2.3.1 4.5-.6 6.3-1.9 1.6-1.2 2.4-3.1 2.4-5.5 0-5-2.9-7.4-8.7-7.4H368v14.7l8.8.1ZM444.9 236.8h29.8v6.7h-21.9v12.1h16.3v6.7h-16.3v12.1h21.9v6.7h-29.8zM547.8 236.8h7.7l17.6 44.1H565l-4.1-10.2h-18.7l-4 10.2h-8.1l17.7-44.1Zm11 27.4-7.2-18.8-7.2 18.8h14.4ZM640.6 243.4h-13.3v-6.7h34.6v6.7h-13.3v37.5h-8zM720.3 279c-3.5-1.9-6.4-4.7-8.4-8.1-2.1-3.6-3.2-7.8-3.1-12-.1-4.2 1-8.4 3.1-12 2-3.4 4.9-6.2 8.4-8.1 3.7-2 7.8-3 12-2.9 4.1-.1 8.2.9 11.9 2.9 3.5 1.9 6.4 4.7 8.4 8.1 2.1 3.7 3.2 7.8 3.1 12 .1 4.2-1 8.3-3.1 12-2 3.4-4.9 6.2-8.4 8.1-7.6 3.9-16.5 3.9-23.9 0Zm19.6-6.3c2.3-1.3 4.2-3.2 5.5-5.5 2.7-5.2 2.7-11.3 0-16.5-1.3-2.3-3.2-4.2-5.5-5.5-2.4-1.3-5-2-7.7-2s-5.4.6-7.8 2c-2.3 1.3-4.2 3.2-5.5 5.5-2.7 5.2-2.7 11.3 0 16.5 1.3 2.3 3.2 4.2 5.5 5.5 2.4 1.3 5 2 7.7 2 2.7-.1 5.4-.7 7.8-2ZM801.6 236.8h18.5c4.9 0 8.6 1.2 11.3 3.6 2.7 2.4 4 5.9 4 10.4.1 3-.8 5.9-2.4 8.3-1.7 2.3-4.1 4-6.8 4.9l8.9 17h-8.2l-8.5-16.2h-8.7V281h-8l-.1-44.2Zm16.9 21.4c2.3.1 4.5-.6 6.3-1.9 1.6-1.2 2.4-3.1 2.4-5.5 0-5-2.9-7.4-8.7-7.4h-8.9v14.7l8.9.1Z" />
        </g>
        <path
          d="M358.5 99.7c.3-.2.5-.2.6-.2 12.5-6 23.5-14.8 32.2-25.6 4.4-5.3 7.9-11.2 9.2-18.1 1.5-7.2-.2-13.4-6-18.3-3.2-2.7-6.7-4.5-11-3.3-2.5.6-4.8 1.6-7 3-10.1 6.7-15.8 16.4-17.8 28.3-1.4 8.6-1.8 17.4-1.1 26.1.2 2.6.6 5.2.9 8.1m-20.7 42.1c-12.4 2.7-25.1 4.1-37.7 4.4v30.3c0 6.2-2.4 11.2-7.6 14.6-10.6 7-24.9-.3-25.5-13V19.2c0-8.1 4.7-14.6 12.1-16.7 8.8-2.5 17.9 2.6 20.4 11.4.3 1.2.5 2.5.6 3.7.1 1.1.1 2.3.1 3.3v91.7c8.9-.2 17.9-1.2 26.6-2.8-.4-2.4-.8-4.9-1.1-7.2-1.2-9.3-1.5-18.6-1-27.9.6-9.2 1.5-18.4 4.5-27.1 6.2-17.7 17.1-31.8 33.7-41.1C370.8 2 379.8-.1 388.8.4c8.8.5 17.2 3.7 24.1 9 13.1 9.7 20.2 22.8 21.1 39.2.6 11.6-2.5 22.3-7.9 32.4-5.9 10.9-13.9 20.2-23.1 28.5-9.4 8.5-20.1 15.6-31.7 21-.3.2-.7.3-1.4.6 2.2 2.5 4.1 5.1 6.3 7.5 9.1 9.5 20.4 15.1 33 18.3 5.5 1.4 11.3 2.2 16.9 3.2 9.1 1.5 15.3 10.1 13.8 19.2-1.1 6.9-6.5 12.4-13.4 13.7-2.9.6-5.8.1-8.7-.3-11.9-1.5-23.6-4.7-34.7-9.5-19.5-8.6-34.7-22.1-45-40.8 0-.2-.1-.4-.3-.6M677.8.4c.4.2.9.2 1.3.4 3.7 1.1 6.1 4.5 6 8.3v56.6c0 14.4-.1 28.9 0 43.3-.1 8.4 1.1 16.7 3.6 24.7 6.5 20 20.2 32.2 40.3 37.6 4.8 1.3 9.7 2 14.6 2.1 4.6.1 8.2 3.8 8.2 8.4.1 4.5-3.5 8.3-8 8.3h-.4c-8.2-.3-16.2-1.9-24-4.5-11.1-3.7-21.1-9.4-29.4-17.7-7.9-7.9-13.7-17.5-17-28.2-3.1-10-4.7-20.4-4.6-30.9V9.3c0-4.9 2.3-7.9 7-9 .1 0 .1-.1.2-.2h2.3l-.1.3Z"
          fill="#3D3D3D"
        />
        <path
          d="M803.6.4c.9.3 1.9.6 2.8 1.1 2.7 1.3 4.5 4.2 4.4 7.2 0 32 .1 64.1 0 96.1-.1 10.9-2.2 21.4-7.5 31-6.3 11.4-15.6 19.3-28.3 22.7-1.9.5-3.8.8-5.7.9-4.3.2-8-3-8.5-7.3-.4-4.9 2.6-8.7 7.2-9.4 7.8-1.1 13.8-5.1 18.4-11.3 4-5.5 6.1-11.8 7-18.5.5-4 .7-7.9.7-11.9.1-30.4 0-60.8 0-91.2 0-5 2.2-7.9 7-9.2.1 0 .1-.1.2-.2h2.3ZM711.2.4l1.5.5c3.3 1.1 5.6 4.1 5.8 7.6v95c0 7.8 1.1 15.3 4.5 22.4 4 8.3 10 13.9 19 16.3 1.1.2 2.2.3 3.3.6 4 .9 6.9 4.9 6.4 8.7-.5 4.7-3.8 7.9-8.3 7.8-4.7-.1-9-1.5-13.2-3.5-12.5-6-20.3-16-24.9-28.8-2.1-6.4-3.4-13-3.6-19.8-.2-3.1-.2-6.2-.2-9.2 0-29.4 0-58.9-.1-88.4 0-4.9 3-8.6 7-9.2.1 0 .1-.1.2-.2l2.6.2Z"
          fill="#F1AF1D"
        />
        <path
          d="M833.9.7c3.6-.6 6.6.6 8.7 3.6 1 1.5 1.5 3.2 1.4 5v102.3c-.1 9.4-1.6 18.7-4.4 27.6-6.6 20.7-19.9 35.2-39.7 43.9-9.5 4.2-19.6 6.6-29.9 7.2-4.5.2-7.9-2-9.1-6-1.3-4.5 1.3-9.2 5.8-10.5.7-.2 1.4-.3 2.2-.3 11.4-.4 22.5-3.8 32.2-9.9 11.9-7.5 19.4-18.5 23.2-31.9 2-7.2 3.1-14.6 3-22.1V9.7c0-3.8 1.4-6.7 4.9-8.4.6-.2 1.1-.5 1.7-.6"
          fill="#3D3D3D"
        />
        <path
          d="M474.2 97.6c-.1-7.9 6.2-14.4 14.1-14.6h.1c7.8 0 14.1 6.5 14.2 14.6.1 8-6.3 14.5-14.3 14.6-7.8 0-14.1-6.5-14.1-14.6M474.2 139c-.1-7.9 6.2-14.4 14.1-14.6h.1c7.8 0 14.2 6.6 14.2 14.6 0 7.9-6.3 14.4-14.2 14.6-7.8-.1-14.2-6.6-14.2-14.6M605.4 56.3c0-8.1 6.2-14.6 14.2-14.6 7.8-.1 14.2 6.6 14.2 14.7.1 7.9-6.2 14.4-14.2 14.5h-.1c-7.8 0-14.1-6.5-14.1-14.6M605.4 97.6c0-8.1 6.3-14.6 14.1-14.5 8 .1 14.3 6.6 14.3 14.6s-6.4 14.6-14.2 14.6c-7.9-.1-14.2-6.6-14.2-14.7M474.2 56.3c-.1-7.9 6.2-14.4 14.1-14.5 7.7-.1 14.3 6.6 14.3 14.6.1 8-6.3 14.5-14.3 14.6-7.8-.1-14.1-6.6-14.1-14.7M488.5.4C496.6.4 503 7 503 15.1s-6.6 14.5-14.7 14.5c-7.9 0-14.1-6.6-14.1-15 0-7.8 6.4-14.2 14.3-14.2M605.4 138.9c0-8.1 6.2-14.6 14.1-14.6 8.1 0 14.6 6.5 14.6 14.5 0 8.1-6.5 14.6-14.5 14.6-7.8.1-14.2-6.3-14.2-14.5M575.9 194.8c-7.9-.1-14.2-6.6-14.2-14.5 0-8.1 6.4-14.7 14.2-14.7 7.8 0 14.2 6.6 14.2 14.7 0 8-6.3 14.4-14.2 14.5M532.2 194.8c-8 .3-14.8-6-15.1-14.1-.3-8 6-14.8 14.1-15.1 8-.3 14.8 6 15.1 14.1v.4c0 8.2-6.2 14.7-14.1 14.7M605.4 14.9c0-8.2 6.2-14.6 14.3-14.5 7.9 0 14.1 6.6 14.1 14.6.2 7.8-5.9 14.4-13.7 14.6-7.8.2-14.4-5.9-14.6-13.7-.1-.3-.1-.6-.1-1"
          fill="#F1AF1D"
        />
      </g>
    </SvgIcon>
  );
});

const KuuIcon = memo((props) => {
  return (
    <SvgIcon
      style={{
        width: "100%",
        height: "100%",
        cursor: "pointer",
        ...props.style,
      }}
      viewBox="0 0 156 222"
    >
      <defs>
        <path id="prefix__a" d="M.449.159h20.228v55.999H.45z" />
        <path id="prefix__c" d="M0 0h89.377v11.616H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M0 130.764c.24-1.21.42-2.436.729-3.628 1.436-5.538 3.774-10.611 7.885-14.71 3.118-3.107 6.865-5.106 11.16-6.076.142-.032.288-.044.495-.074-.101 2.935-.206 5.821-.3 8.707-.133 4.14-.242 8.28-.407 12.418-.019.467-.236.999-.528 1.37-2.555 3.243-4.694 6.67-5.254 10.88-.489 3.673-.191 7.307.609 10.89.555 2.49 1.292 4.942 2.017 7.39.516 1.742.019 3.22-1.465 3.956-1.504.744-2.986.295-4.02-1.244-3.728-5.55-7.173-11.263-9.13-17.71-.739-2.43-1.095-4.978-1.628-7.47-.037-.167-.108-.327-.163-.49v-4.209z"
          fill="#F1AF1D"
        />
        <path
          d="M79.152 89.579c2.778-.127 7.162-.277 11.54-.538 6.302-.375 12.548-1.193 18.67-2.778 4.86-1.258 9.56-2.927 13.62-6.003 3.935-2.98 6.29-6.81 6.26-11.893-.022-3.732-.767-7.322-2.147-10.775-2.837-7.104-7.693-12.606-13.86-16.961-11.089-7.83-23.562-11.103-37.03-10.924-5.76.077-11.405.963-16.922 2.6-9.091 2.696-17.225 7.052-23.83 13.992-4.899 5.146-8.136 11.148-9.201 18.219-.447 2.97-.552 5.949.56 8.838 1.513 3.934 4.447 6.557 7.978 8.606 4.724 2.74 9.907 4.186 15.212 5.244 9.091 1.815 18.306 2.22 29.15 2.373m-1.627 10.174c-6.916-.18-13.84-.22-20.746-.58-6.567-.343-13.124-.94-19.67-1.584-3.54-.348-6.826-1.76-10.1-3.08-5.534-2.228-10.626-5.175-14.8-9.524-4.818-5.022-7.362-11.054-7.393-17.981-.068-15.906 5.324-29.856 15.746-41.799C31.703 12.44 45.774 4.54 62.375 1.497c21.826-4 42.162-.08 60.39 12.887 12.083 8.595 20.573 19.964 25 34.218 1.903 6.129 2.827 12.419 2.635 18.83-.202 6.712-2.79 12.47-7.44 17.304-5.051 5.249-11.36 8.39-18.076 10.9-4.04 1.51-8.219 2.169-12.479 2.567-8.074.754-16.163 1.202-24.271 1.285-3.535.037-7.07.006-10.606.006 0 .087-.002.173-.003.26M32.937 101.775h89.294c.05.755.116 1.475.142 2.195.221 6.15.43 12.3.654 18.45.07 1.918.31 3.84.22 5.748-.07 1.527-.423 3.064-.821 4.55-3.187 11.912-8.165 22.994-15.502 32.95-4.36 5.916-9.41 11.132-15.842 14.831-4.587 2.638-9.475 4.429-14.86 4.02-5.12-.39-9.729-2.367-13.99-5.177-6.233-4.11-11.146-9.533-15.354-15.623-7.097-10.269-11.923-21.558-14.728-33.694-.426-1.846-.137-3.873-.076-5.813.162-5.145.378-10.29.577-15.434.089-2.31.188-4.618.286-7.003"
          fill="#F1AE1C"
        />
        <g transform="translate(134.5 106.077)">
          <mask id="prefix__b" fill="#fff">
            <use xlinkHref="#prefix__a" />
          </mask>
          <path
            d="M.45.159c6.388 1.395 11.328 4.733 14.82 10.076 3.43 5.25 5.566 10.972 5.398 17.352-.13 4.914-1.554 9.532-3.507 14.001-2.033 4.653-4.659 8.972-7.499 13.164-.93 1.376-2.412 1.757-3.85 1.075-1.448-.686-2.094-2.14-1.566-3.75 1.158-3.537 2.079-7.118 2.629-10.81 1.04-6.982-.536-13.176-5.177-18.556-.32-.371-.532-.955-.551-1.45C.89 14.441.675 7.62.45.8c-.005-.178 0-.356 0-.64"
            fill="#F1AF1D"
            mask="url(#prefix__b)"
          />
        </g>
        <path
          d="M125.555 129.117l-1.02-27.385h4.48c.732 0 1.464-.022 2.195.007 1.005.039 1.274.324 1.307 1.326.193 5.992.378 11.985.576 17.978.072 2.158.168 4.316.265 6.474.06 1.321-.196 1.598-1.557 1.6-2.038.002-4.076 0-6.246 0M30.673 101.75l-1.015 27.355c-2.37 0-4.646.026-6.92-.015-.652-.013-.914-.492-.892-1.147.134-3.923.253-7.848.38-11.772.116-3.558.236-7.117.357-10.675.03-.851.118-1.703.098-2.553-.019-.785.377-1.17 1.075-1.18 2.274-.034 4.549-.013 6.917-.013"
          fill="#F1AF1E"
        />
        <path
          d="M78.03 71.46c1.757-.024 3.097-1.358 4.187-3.002.15-.227-.023-.935-.258-1.083-.29-.183-.892-.155-1.2.037-.445.279-.737.797-1.114 1.193-1.188 1.247-2.922 1.235-4.104-.025-.375-.4-.67-.918-1.118-1.19-.306-.186-.914-.186-1.2.007-.229.155-.374.86-.217 1.09 1.104 1.632 2.441 2.975 5.025 2.972m-18.507-8.332c.018-2.055-.451-3.984-1.483-5.763-2.705-4.666-8.115-5.296-11.484-1.342-3.333 3.91-3.236 10.59.21 14.403 2.776 3.07 7.037 3.17 9.923.19 2.013-2.078 2.8-4.647 2.834-7.488m36.164-.004c.005 2.739.815 5.12 2.395 7.023 2.782 3.352 7.266 3.532 10.232.413 3.609-3.797 3.713-10.762.219-14.674-2.982-3.338-7.535-3.294-10.422.137-1.718 2.043-2.43 4.457-2.424 7.101M79.152 89.58c-10.844-.153-20.059-.558-29.15-2.373-5.305-1.059-10.488-2.503-15.211-5.244-3.531-2.049-6.466-4.672-7.98-8.606-1.11-2.89-1.006-5.869-.56-8.838 1.066-7.071 4.303-13.073 9.202-18.22 6.605-6.939 14.739-11.296 23.83-13.992 5.517-1.636 11.162-2.523 16.922-2.6 13.468-.178 25.941 3.094 37.03 10.925 6.167 4.355 11.023 9.857 13.86 16.961 1.38 3.453 2.125 7.043 2.147 10.774.03 5.084-2.325 8.913-6.26 11.894-4.06 3.076-8.76 4.745-13.62 6.003-6.122 1.585-12.368 2.403-18.67 2.778-4.378.26-8.762.411-11.54.538"
          fill="#FEFAEA"
        />
        <path
          d="M108.534 55.885c-2.982-3.338-7.536-3.294-10.422.137-1.718 2.043-2.43 4.457-2.424 7.101.005 2.739.815 5.12 2.395 7.023 2.782 3.352 7.266 3.532 10.232.413 3.609-3.797 3.712-10.762.219-14.674M46.557 56.022c-3.333 3.911-3.236 10.59.21 14.403 2.776 3.07 7.037 3.17 9.923.19 2.013-2.078 2.8-4.647 2.834-7.488.018-2.055-.451-3.984-1.483-5.763-2.705-4.666-8.115-5.295-11.484-1.342M81.959 67.375c-.29-.183-.892-.155-1.199.038-.446.278-.738.796-1.115 1.192-1.188 1.247-2.922 1.235-4.104-.025-.375-.4-.67-.918-1.118-1.19-.306-.186-.914-.186-1.2.007-.229.155-.374.86-.217 1.091 1.104 1.631 2.441 2.974 5.025 2.971 1.756-.023 3.096-1.357 4.186-3 .15-.228-.023-.936-.258-1.084"
          fill="#101111"
        />
        <g transform="translate(33 209.5)">
          <mask id="prefix__d" fill="#fff">
            <use xlinkHref="#prefix__c" />
          </mask>
          <path
            d="M52.758 11.616c-1.337-.001-2.673-.005-4.01-.004-4.04 0-8.08.003-12.12.004-7.053-.438-14.122-.713-21.154-1.376-4.041-.382-8.03-1.356-12.027-2.15-.9-.18-1.783-.635-2.58-1.12-1.143-.696-1.179-1.678.026-2.22 1.76-.79 3.61-1.473 5.49-1.895C12.192 1.547 18.114.953 24.048.667c7.425-.359 14.86-.62 22.293-.663 9.144-.052 18.28.358 27.376 1.382 4.266.48 8.535 1.005 12.639 2.358.8.263 1.59.632 2.297 1.087.953.612.972 1.419.01 2.028-.837.53-1.77 1.034-2.726 1.226-3.993.806-7.984 1.773-12.025 2.155-7.033.663-14.102.938-21.155 1.376"
            fill="#4C4C4D"
            mask="url(#prefix__d)"
          />
        </g>
      </g>
    </SvgIcon>
  );
});

export { LogoFooter, Logo, KuuIcon };
